<template>
	<div>
		<TopBanner
			:is-editorial="plfm.isEditorial"
			:custom-msg="customizations.bannerMsg"
		/>
		<Navbar
			:is-editorial="plfm.isEditorial"
			:logo-url="customizations.logo"
			:products="items"
			:collections="collections"
		/>
		<NavbarMobile
			:is-editorial="plfm.isEditorial"
			:logo-url="customizations.logo"
			@open-drawer="isMobileDrawerOpen = true"
		/>
		<v-navigation-drawer
			v-model="isMobileDrawerOpen"
			temporary
			fixed
			location="right"
			width="320"
		>
			<NavDrawer
				:is-mobile-drawer-open="isMobileDrawerOpen"
				:collections="collections"
				@close-drawer="isMobileDrawerOpen = false"
			/>
		</v-navigation-drawer>
	</div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import TopBanner from "./TopBanner.vue";
import Navbar from "./Navbar.vue";
import NavbarMobile from "./NavbarMobile.vue";
import NavDrawer from "./NavDrawer.vue";
import type { MenuItem } from "@/types/general";

const { plfm, platform, platformOrgId } = storeToRefs(usePlatformStore());
const isMobileDrawerOpen = ref(false);

const customizations = computed(() => {
	const { mode } = useRuntimeConfig().public;
	const settings = platform.value?.platform_settings?.[0];
	const baseUrl =
		mode === "production" || mode === "staging"
			? "https://tengiva-ecm-wpc-prod.imgix.net"
			: "https://tengiva-ecm-wpc-dev.imgix.net";

	return {
		bannerMsg: settings?.platform_branding.custom_standard_titlebar_msg,
		logo: `${baseUrl}/${settings?.id}/${settings?.platform_branding.logo}`,
	};
});

const { listByOrganizationMarketplace } = usePMApi();
const { $sentry } = useNuxtApp();

const collections = ref<MenuItem[]>([]);

const { t } = useI18n();

onMounted(async () => {
	try {
		const { response, error } = await listByOrganizationMarketplace(platformOrgId.value);

		if (error) throw error;

		if (response?.data) {
			const collectionsItems = response?.data?.map(collection => ({
				label: collection.name,
				url: { name: "collections-slug", params: { slug: collection.slug } },
				isBold: false,
			}));

			if (collectionsItems?.length) {
				collections.value = [
					{
						label: t("components.nav.collections_submenu_all"),
						url: {
							name: "collections",
						},
						isBold: true,
					},
					...collectionsItems,
				];
			}
		}
	} catch (error) {
		$sentry.captureException(`Cannot fetch platform collections for nav menu, ${error}`);
	}
});

const items = ref<MenuItem[]>([
	{
		label: "All products",
		isBold: true,
		hasSubMenu: false,
		subMenu: [],
		url: "/products",
	},
	{
		label: "Newest materials",
		isBold: true,
		hasSubMenu: false,
		subMenu: [],
		url: "/newest-materials",
	},
	{
		label: "Category 1",
		hasSubMenu: true,
		subMenu: [
			{
				label: "Subcategory 1",
				url: "/subcategory-1",
			},
			{
				label: "Subcategory 2",
				url: "/subcategory-2",
			},
			{
				label: "Subcategory 3",
				url: "/subcategory-4",
			},
		],
	},
]);
</script>
