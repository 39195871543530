<template>
	<div
		class="tg-title-font justify-between h-[72px] items-center w-full fixed z-10 top-[30px] page-px hidden lg:flex bg-white"
	>
		<div class="flex items-center gap-1">
			<NuxtLink
				v-if="!props.isEditorial"
				to="/"
			>
				<img
					:src="props.logoUrl"
					alt=""
					class="h-6 mr-4"
				/>
			</NuxtLink>

			<NuxtLink
				to="/textiles"
				class="desktop-nav-item"
				@click="handleTextileLinkClick"
			>
				{{ $t("components.nav.products") }}
			</NuxtLink>
			<!-- <NavMenu
				:section="$t('components.nav.products')"
				:items="props.products"
			/> -->

			<NavMenu
				v-if="props.collections?.length"
				:section="$t('components.nav.collections')"
				:items="props.collections"
			/>

			<NuxtLink
				to="/merchants"
				class="desktop-nav-item"
				>{{ $t("components.nav.merchants") }}</NuxtLink
			>

			<!-- <NuxtLink
				to="/blog"
				class="desktop-nav-item"
				>Blog</NuxtLink
			> -->
		</div>

		<NuxtLink
			v-if="props.isEditorial"
			class="absolute left-1/2 -translate-x-1/2"
			to="/"
		>
			<img
				:src="props.logoUrl"
				alt=""
				class="h-6"
			/>
		</NuxtLink>

		<div class="flex items-center gap-2">
			<SearchInput
				model-value=""
				:placeholder="$t('components.nav.search_placeholder')"
				clearable
				disabled
				class="lg:min-w-[115px] xl:min-w-[200px]"
			/>
			<AccountMenu />

			<NuxtLink
				class="flex items-center gap-1 cursor-pointer"
				:to="{ name: 'my-cart' }"
			>
				<Icon
					name="md-local_mall"
					class="mr-1"
				/>
				<Badge
					v-if="numOfTextileItems !== null"
					:visible="true"
					:content="numOfTextileItems as number ?? 0"
					:color="numOfTextileItems as number > 0 ? 'red' : '#6E6E73'"
					class="-mt-[20px] mr-[7px]"
				/>
				<span class="translate-y-[2px]">Cart</span>
			</NuxtLink>
		</div>
	</div>
</template>

<script lang="ts" setup>
import NavMenu from "./NavMenu.vue";
import AccountMenu from "./AccountMenu.vue";
import type { MenuItem } from "@/types/general";

const { platform } = storeToRefs(usePlatformStore());

interface Props {
	isEditorial: boolean;
	logoUrl: string;
	products: MenuItem[];
	collections: MenuItem[];
}
const props = defineProps<Props>();

const cartStore = useCartStore();
const { numOfTextileItems } = storeToRefs(cartStore);

const catalogStore = useCatalogStore();
const { setProducts, setFilters, setOrder } = catalogStore;

const router = useRouter();

const handleTextileLinkClick = () => {
	if (router.currentRoute?.value.name === "textiles") {
		setFilters({});
		setOrder("");
		setProducts(platform.value?.platform_settings?.[0]?.id);
	}
};
</script>
