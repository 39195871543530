<template>
	<v-menu v-if="isLoggedIn">
		<template #activator="{ props }">
			<div
				v-bind="props"
				class="flex items-center desktop-nav-item"
			>
				<Icon
					name="md-account_circle"
					class="mr-2"
				/>
				{{ $t("components.nav.my_account") }}
				<Icon name="md-expand_more" />
			</div>
		</template>
		<div class="tg-title-font w-full bg-white shadow rounded-sm py-2">
			<NuxtLink
				to="/orders"
				class="flex px-3 py-2 gap-2 hover:bg-neutral-50"
			>
				<Icon name="md-package" />
				<div>{{ $t("components.nav.orders") }}</div>
			</NuxtLink>
			<div
				class="flex px-3 py-2 gap-2 hover:bg-neutral-50 cursor-pointer"
				@click="signOut"
			>
				<Icon name="md-logout" />
				<div>{{ $t("components.nav.logout") }}</div>
			</div>
		</div>
	</v-menu>
	<div
		v-else
		class="flex items-center"
	>
		<NuxtLink
			class="desktop-nav-item mr-[-1px]"
			to="/auth"
		>
			<div>{{ $t("components.nav.login") }}</div>
		</NuxtLink>
		<div class="h-[20px] w-[1px] bg-neutral-200"></div>
		<NuxtLink
			class="desktop-nav-item ml-[-1px]"
			to="/auth?tab=sign_up&accountType=business"
		>
			<div>{{ $t("components.nav.create_account") }}</div>
		</NuxtLink>
	</div>
</template>

<script setup lang="ts">
const { isLoggedIn } = storeToRefs(useUserStore());
const { userLogout } = useUserStore();
const router = useRouter();
const { clearCartData } = useCartStore();

async function signOut() {
	await userLogout();
	clearCartData();
	router.push("/");
}
</script>
