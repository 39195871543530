<template>
	<div class="fixed z-10 page-px lg:hidden md:mt-[30px] w-full bg-white">
		<div class="flex justify-between items-center mt-3">
			<div class="flex items-center gap-1">
				<Icon
					name="md-menu"
					class="mr-2 cursor-pointer"
					@click="emit('openDrawer')"
				/>

				<NuxtLink
					v-if="!props.isEditorial"
					to="/"
				>
					<img
						:src="props.logoUrl"
						alt=""
						class="h-5 mr-4"
					/>
				</NuxtLink>
			</div>

			<NuxtLink
				v-if="props.isEditorial"
				class="absolute left-1/2 -translate-x-1/2"
				to="/"
			>
				<img
					:src="props.logoUrl"
					alt=""
					class="h-5"
				/>
			</NuxtLink>

			<NuxtLink
				class="flex items-center gap-1 cursor-pointer"
				:to="{ name: 'my-cart' }"
			>
				<Icon
					name="md-local_mall"
					class="mr-1"
				/>
				<Badge
					v-if="numOfTextileItems !== null"
					:visible="true"
					:content="numOfTextileItems as number ?? 0"
					:color="numOfTextileItems as number > 0 ? 'red' : '#6E6E73'"
					class="-mt-[20px] mr-[7px]"
				/>
			</NuxtLink>
		</div>
		<SearchInput
			model-value=""
			:placeholder="$t('components.nav.search_placeholder')"
			clearable
			disabled
			class="w-full py-3"
		/>
	</div>
</template>

<script lang="ts" setup>
const cartStore = useCartStore();
const { numOfTextileItems } = storeToRefs(cartStore);

interface Props {
	isEditorial: boolean;
	logoUrl: string;
}
const props = defineProps<Props>();

const emit = defineEmits<{
	(e: "openDrawer"): void;
}>();
</script>
