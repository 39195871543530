<template>
	<v-menu
		v-model="isOpen"
		:close-on-content-click="!hovered?.hasSubMenu"
		location="bottom"
	>
		<template #activator="{ props }">
			<div
				v-bind="props"
				class="flex items-center desktop-nav-item"
			>
				<div>{{ vprops.section }}</div>
				<Icon
					name="md-chevron_right"
					:class="isOpen ? '-rotate-90' : 'rotate-90' + ' transition-transform'"
				/>
			</div>
		</template>
		<div class="flex shadow-md mt-3">
			<div class="col1 w-[300px] bg-white">
				<div
					v-for="item in vprops.items"
					:key="item.label"
					class="flex"
				>
					<NuxtLink
						v-if="!item.hasSubMenu"
						:to="item.url"
						class="item hover:bg-gray-100"
						:class="{
							'font-bold': item.isBold,
						}"
					>
						{{ item.label }}
					</NuxtLink>
					<div
						v-else
						class="flex justify-between items-center w-full item hover:bg-gray-100"
						@click="toggleSubMenu(item.label)"
						@mouseenter="hoverSubMenuToggle(item.label)"
						@mouseleave="hoverSubMenuToggle(item.label)"
					>
						<div>{{ item.label }}</div>
						<Icon
							v-if="item.hasSubMenu"
							name="md-chevron_right"
						/>
					</div>
				</div>
			</div>
			<div
				v-if="active"
				class="col2 w-[300px] bg-gray-100"
			>
				<NuxtLink
					v-for="item in active.subMenu"
					:key="item.label"
					:to="item.url"
					class="block w-full item"
					>{{ item.label }}</NuxtLink
				>
			</div>
		</div>
	</v-menu>
</template>

<script lang="ts" setup>
import type { MenuItem } from "@/types/general";
interface Props {
	section: string;
	items: MenuItem[];
}
const vprops = defineProps<Props>();

const isOpen = ref(false);
const active = ref<MenuItem>();
const hovered = ref<MenuItem>();

function toggleSubMenu(label: string) {
	active.value =
		active.value && active.value.label === label ? undefined : vprops.items.find(item => item.label === label);
}

function hoverSubMenuToggle(label: string) {
	hovered.value =
		hovered.value && hovered.value.label === label ? undefined : vprops.items.find(item => item.label === label);
}

watch(isOpen, newV => {
	if (newV === false)
		setTimeout(() => {
			active.value = undefined;
			hovered.value = undefined;
		}, 400);
});
</script>

<style scoped>
.item {
	@apply w-full pl-6 pr-5 py-3 cursor-pointer;
}
</style>
